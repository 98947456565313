import React from 'react'
import {
  Button,
  Heading,
  InView,
  match,
  P,
  reduceImages,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import { formatISO } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  Link as I18nLink,
  Trans,
  useI18next,
} from 'gatsby-plugin-react-i18next'
import styled, { css } from 'styled-components'

import { formatPublishDate } from '../../common/utils'
import ParticleBackdrop from '../ParticleBackdrop/ParticleBackdrop'

const SPEAKERS = [
  {
    displayName: 'Barack Obama',
    link: '/videos/a-conversation-with-president-barack-obama',
    image: 'hero-barack',
    date: 'September 22, 2022',
    imageOffsetRight: 73,
  },
  {
    displayName: 'Michelle Obama',
    link: '/videos/a-moderated-conversation-with-former-first-lady-michelle-obama',
    image: 'hero-michelle',
    date: 'February 27, 2018',
    imageOffsetRight: 63,
  },
  {
    displayName: 'Bill Clinton',
    link: '/videos/a-presidential-fireside-chat',
    image: 'hero-bill',
    date: 'June 15, 2015',
    imageOffsetRight: 63,
  },
  {
    displayName: 'Arianna Huffington',
    link: '/videos/the-sleep-revolution',
    image: 'hero-arianna',
    date: 'March 31, 2016',
    imageOffsetRight: 63,
  },
  {
    displayName: 'David Cronenberg',
    link: '/videos/modern-technology-in-philosophy',
    image: 'hero-david',
    date: 'December 8, 2015',
    imageOffsetRight: 63,
  },
  {
    displayName: 'Margaret Atwood',
    link: '/videos/what-does-creative-inspiration-mean-in-todays-society',
    image: 'hero-margaret',
    date: 'December 8, 2015',
    imageOffsetRight: 63,
  },
  {
    displayName: 'Platon',
    link: '/videos/powerful-portraits-an-intimate-look-at-humanity-and-leadership',
    image: 'hero-platon',
    date: 'June 18, 2017',
    imageOffsetRight: 63,
  },
]

const IntroSectionV3 = () => {
  const containerRef = React.useRef()
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [queuedSlideIndex, setQueuedSlideIndex] = React.useState(
    activeSlideIndex + 1
  )
  const [totalSlides] = React.useState(SPEAKERS?.length)
  const [shrinkDashes, setShrinkDashes] = React.useState(false)

  const imageQuery = useStaticQuery(graphql`
    {
      heroImages: allFile(
        filter: { relativePath: { glob: "Home/Hero/**/*.*" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                breakpoints: [385, 770, 960, 1350, 1920]
                sizes: "(min-width: 960px) 605px, 308px"
              )
            }
          }
        }
      }
    }
  `)
  const heroImages = reduceImages(imageQuery.heroImages.edges)

  React.useEffect(() => {
    let switchSlideTimeout
    const slideShowTimeout = setTimeout(() => {
      setShrinkDashes(true)
      switchSlideTimeout = setTimeout(() => {
        setActiveSlideIndex(queuedSlideIndex)
      }, 600)
    }, 3500)
    return () => {
      slideShowTimeout && clearTimeout(slideShowTimeout)
      switchSlideTimeout && clearTimeout(switchSlideTimeout)
    }
  }, [queuedSlideIndex])

  React.useEffect(() => {
    const shrinkDashTimeout = setTimeout(() => {
      setShrinkDashes(false)
      const nextSlide =
        activeSlideIndex === totalSlides - 1 ? 0 : activeSlideIndex + 1
      setQueuedSlideIndex(nextSlide)
    }, 600)
    return () => {
      shrinkDashTimeout && clearTimeout(shrinkDashTimeout)
    }
  }, [activeSlideIndex, totalSlides])

  const activeSpeaker = SPEAKERS[activeSlideIndex]

  return (
    <ThemeProvider themeType="onPrimary">
      <Box
        bgcolor="#01299A"
        position="relative"
        ref={containerRef}
        overflow="hidden"
      >
        <InView>
          <BackdropContainer>
            <ParticleBackdropWrapper>
              <ParticleBackdrop
                showBackdrop={true}
                parentRef={containerRef}
                clearColor={0x022896}
                particleColors={[0x0343fb]}
              />
            </ParticleBackdropWrapper>
          </BackdropContainer>
        </InView>
        <Container maxWidth="lg">
          <Hero>
            <Content>
              <Heading component="h1" variant="h3">
                <TopDash $shrink={shrinkDashes}>
                  <span />
                </TopDash>
                <Trans ns="index">
                  Bringing
                  <StyledBr /> the future of health to life
                </Trans>
              </Heading>
              <StyledP>
                <Trans ns="index">
                  Great ideas shouldn’t be kept to themselves.{' '}
                  <Box component="span" display={{ xs: 'none', sm: 'inline' }}>
                    Not if we want to make the world a better place.
                  </Box>{' '}
                  When we collaborate, we create opportunities to unlock true
                  innovation. And spark the ideas that will change our future,
                  for good.
                </Trans>
              </StyledP>
              <DesktopButton
                component={I18nLink}
                size="large"
                to={activeSpeaker?.link}
              >
                <Trans ns="common">Watch</Trans>
                {` ${activeSpeaker?.displayName}`}
              </DesktopButton>
            </Content>
            <SlideContainer>
              {SPEAKERS.map((entry, index) => (
                <Slide
                  key={index}
                  {...entry}
                  isActive={index === activeSlideIndex}
                  index={index}
                  image={heroImages[entry.image]}
                  totalSlides={SPEAKERS.length}
                  shrinkDashes={shrinkDashes}
                  setActiveSlideIndex={setActiveSlideIndex}
                />
              ))}
            </SlideContainer>
            <Pagination
              totalSlides={SPEAKERS.length}
              index={activeSlideIndex}
              setActiveSlideIndex={setActiveSlideIndex}
              shrinkDashes={shrinkDashes}
            />
            <PaddedP variant="blurb2">
              <Trans ns="index">
                Great ideas shouldn’t be kept to themselves.{' '}
                <Box component="span" display={{ xs: 'none', sm: 'inline' }}>
                  Not if we want to make the world a better place.
                </Box>{' '}
                When we collaborate, we create opportunities to unlock true
                innovation. And spark the ideas that will change our future, for
                good.
              </Trans>
            </PaddedP>
          </Hero>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

const Pagination = (props) => {
  const { totalSlides, index, setActiveSlideIndex, shrinkDashes } = props
  return (
    <StyledPagination>
      <Dots>
        {Array.from({ length: totalSlides }).map((dotEntry, dotIndex) => (
          <li key={`dot-${dotIndex + 1}-slide-${index + 1}`}>
            <Dot
              $isActive={dotIndex === index}
              onClick={() => {
                setActiveSlideIndex(dotIndex)
              }}
            />
          </li>
        ))}
      </Dots>
      <Box pt={2}>
        <ThinDash $shrink={shrinkDashes}>
          <span />
        </ThinDash>
        <SlideNums variant="body2">
          {index + 1}/{totalSlides}
        </SlideNums>
      </Box>
    </StyledPagination>
  )
}

const Slide = (props) => {
  const {
    isActive,
    image,
    date,
    displayName,
    link,
    imageOffsetRight,
    shrinkDashes,
  } = props
  const { language } = useI18next()
  return (
    <SlideInner $isActive={isActive}>
      <SpeakerContainer>
        <Square>
          <SlideImage
            role="presentation"
            alt=""
            image={getImage(image)}
            style={{ right: `${imageOffsetRight}%` }}
            objectPosition="center bottom"
            objectFit="cover"
          />
          <Circle />
        </Square>
      </SpeakerContainer>
      <MobileButton
        size="large"
        component={I18nLink}
        to={link}
        disabled={!isActive}
      >
        <Trans ns="common">Watch</Trans>
        {` ${displayName}`}
      </MobileButton>
      <SlideContent>
        <SlideLabel>
          <Vertical>
            <Dash $shrink={shrinkDashes}>
              <span />
            </Dash>{' '}
            {displayName}
            <br />
            {formatPublishDate(formatISO(new Date(date)), language)}
          </Vertical>
        </SlideLabel>
      </SlideContent>
    </SlideInner>
  )
}

const heroCircleDiameterMobile = 280
const verticalMarginsSpeakerMobile = 0.12 * heroCircleDiameterMobile

const heroCircleDiameter = 550
const verticalMarginsSpeaker = 0.12 * heroCircleDiameter

const Hero = styled.div`
  position: relative;
  padding: 70px 0 50px;

  ${match.isSM} {
    padding: 0;
    position: relative;
    height: ${verticalMarginsSpeakerMobile * 2 +
    heroCircleDiameterMobile +
    300}px;
  }

  ${match.isMD} {
    position: relative;
    height: ${verticalMarginsSpeaker * 2 + heroCircleDiameter + 100}px;
  }
`

const Content = styled.div`
  /* maybe need to remove lft top on mobile */
  left: 0;
  top: 20px;
  z-index: 1;
  font-size: 14px;

  ${match.isSM} {
    position: absolute;
    font-size: 16px;
    top: calc(50%);
    transform: translateY(-50%);
    max-width: 48%;
  }

  ${match.isMD} {
    max-width: 34%;
  }
`

const SlideContainer = styled.div`
  height: ${verticalMarginsSpeakerMobile * 2 + heroCircleDiameterMobile}px;
  position: relative;

  ${match.isSM} {
    height: 100%;
    /* top: 100px; */
  }
`
const StyledP = styled(P)`
  display: none;
  ${match.isSM} {
    display: block;
  }
`
const PaddedP = styled(P)`
  margin-bottom: 40px;
  ${match.isSM} {
    display: none;
  }
`

const SlideNums = styled(P)`
  display: inline;
`

const Dash = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 25px;
  height: 2px;

  span {
    position: absolute;
    left: -5px;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: ${({ $shrink }) => ($shrink ? '0 !important' : '25px')};
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s;

    ${match.isSM} {
      left: -10px;
    }
  }
`

const TopDash = styled(Dash)`
  span {
    left: -5px;
    width: 20px;
  }

  ${match.isSM} {
    span {
      width: 25px;
    }
  }
`

const ThinDash = styled(Dash)`
  width: 30px;
  height: 1px;

  span {
    left: 10px;
    width: 30px;
    height: 1px;
  }
`

const StyledBr = styled.br`
  display: none;
  ${match.isSM} {
    display: inline;
  }
`

const SlideInner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${({ $isActive }) => ($isActive ? 'all' : 'none')};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 0.5s;

  ${match.isSM} {
    display: block;
    /* top: 60px; */
  }

  ${match.isMD} {
    /* top: 0; */
  }

  ${({ $isActive }) => {
    if ($isActive) {
      return css`
        ${SlideImage} {
          transform: translateX(60%);

          ${match.isMD} {
            transform: translateX(65%);
          }
        }
      `
    }
  }}
`

const SpeakerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${match.isSM} {
    /* transform: translate(19%, -50%); */
    transform: translate(23%, -50%);
    position: absolute;
    top: 55%;
    width: 100%;
    left: 0;
  }

  ${match.isMD} {
    transform: translate(13%, -50%);
  }
`

const Square = styled.div`
  width: ${heroCircleDiameterMobile}px;
  border-bottom-left-radius: ${heroCircleDiameterMobile / 2}px;
  border-bottom-right-radius: ${heroCircleDiameterMobile / 2}px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;

  /* START - The following ensure that the image is properly masked in safari */
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  /* END - The following ensure that the image is properly masked in safari */

  ${match.isMD} {
    display: block;
    width: ${heroCircleDiameter}px;
    border-bottom-left-radius: ${heroCircleDiameter / 2}px;
    border-bottom-right-radius: ${heroCircleDiameter / 2}px;
    margin-bottom: ${verticalMarginsSpeaker}px;
  }
`
const SlideImage = styled(GatsbyImage)`
  max-height: ${heroCircleDiameterMobile + verticalMarginsSpeakerMobile}px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transform: translateX(50%);
  width: 110%;

  position: absolute;
  right: 63%;
  bottom: 0;

  ${match.isMD} {
    max-height: ${heroCircleDiameter + verticalMarginsSpeaker}px;
    transform: translateX(60%);
  }
`

const Circle = styled.div`
  border-radius: 50%;
  background: #211e1e;
  margin-top: ${verticalMarginsSpeakerMobile}px;
  width: ${heroCircleDiameterMobile}px;
  height: ${heroCircleDiameterMobile}px;

  ${match.isMD} {
    margin-top: ${verticalMarginsSpeaker}px;
    width: ${heroCircleDiameter}px;
    height: ${heroCircleDiameter}px;
  }
`

const SlideContent = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: calc(100% - 20px);

  ${match.isSM} {
    bottom: 30px;
    width: 90px;
    transform: translateY(-10%);
  }

  ${match.isMD} {
    bottom: auto;
    top: 50%;
  }
`

const SlideLabel = styled.div`
  display: block;
  margin: 0 0 0 auto;
  padding: 0;
  width: 90px;
  text-decoration: none;
  color: #ffffff;

  ${match.isSM} {
    height: 150px;
  }
`

const MobileButton = styled(Button)`
  left: 0;
  bottom: 20px;
  text-align: center;
  margin-top: -50px;

  ${match.isSM} {
    display: none;
  }
`
const DesktopButton = styled(Button)`
  display: none;
  ${match.isSM} {
    display: inline-block;
  }
`

const Vertical = styled.div`
  white-space: nowrap;
  transform: rotate(90deg);
  transform-origin: 145px -70px;

  ${match.isSM} {
    transform-origin: 180px -90px;
  }

  ${match.isMD} {
    transform-origin: 140px -50px;
  }
`

const StyledPagination = styled.div`
  display: none;
  width: 90px;
  text-align: right;

  ${match.isSM} {
    display: block;
    bottom: 50px;
    position: absolute;
    right: 10px;
  }

  ${match.isMD} {
    top: 70%;
    bottom: auto;
  }
`

const Dots = styled.ul`
  margin: 0;
  padding: 0 0 0 10px;
  list-style-type: none;

  ${match.isSM} {
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 5px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`

const Dot = styled.button`
  border: none;
  padding: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ $isActive }) =>
    $isActive ? `rgba(255, 255, 255, 1)` : `rgba(255, 255, 255, 0.3)`};
  cursor: pointer;

  &:hover,
  &:focus {
    ${({ $isActive }) =>
      $isActive ? `rgba(255, 255, 255, 1)` : `rgba(255, 255, 255, 0.7)`};
  }
`

const BackdropContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
`

const ParticleBackdropWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`

export default IntroSectionV3
