import React, { useEffect, useRef } from 'react'
import {
  Heading,
  match,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import styled from 'styled-components'

import ThoughtLeader from './ThoughtLeader'

gsap.registerPlugin(ScrollTrigger)

const IdeasByThoughtLeader = ({ items }) => {
  const titleRef = useRef()
  const peopleRefs = useRef([])
  const { t } = useTranslation()

  useEffect(() => {
    if (items) {
      BuildAnimation({ peopleRefs, titleRef })
    }
  }, [items])

  return (
    <ThemeProvider themeType="onWhite">
      <StyledSection name={t('Ideas by thought leader', { ns: 'index' })}>
        <StyledContainer maxWidth="lg">
          <HeadingContainer ref={titleRef}>
            <StyledHeading component="h2" variant="h2">
              <Trans ns="index">Ideas by thought leader</Trans>
            </StyledHeading>
          </HeadingContainer>
          <Grid container spacing={1} justifyContent="center">
            {items ? (
              <ThoughtLeaders thoughLeaders={items} peopleRefs={peopleRefs} />
            ) : (
              <ThoughtLeadersSkeleton />
            )}
          </Grid>
        </StyledContainer>
      </StyledSection>
    </ThemeProvider>
  )
}

const ThoughtLeadersSkeleton = () => {
  return [...Array(8)].map((item, index) => (
    <Grid item xs={6} sm={4} md={3} key={`thoughtLeader@key=${index}`}>
      <Skeleton variant="rect" style={{ height: '300px', width: '100%' }} />
    </Grid>
  ))
}

const ThoughtLeaders = ({ thoughLeaders, peopleRefs }) => {
  return thoughLeaders.map((thoughLeader, index) => (
    <Grid item xs={6} sm={4} md={3} key={`thoughtLeader@key=${index}`}>
      <ThoughtLeader
        elementRef={(el) => {
          peopleRefs.current[index] = el
        }}
        {...thoughLeader}
        src={thoughLeader.image.url}
        url={thoughLeader.url}
      />
    </Grid>
  ))
}

const BuildAnimation = ({ peopleRefs, titleRef }) => {
  const titleTimeline = gsap.timeline()

  titleTimeline.fromTo(
    titleRef.current,
    {
      x: 0,
      y: 70,
      opacity: 0,
    },
    {
      x: 0,
      y: 0,
      duration: 1,
      opacity: 1,
    },
    0
  )

  ScrollTrigger.create({
    trigger: titleRef.current,
    start: 'top bottom',
    animation: titleTimeline,
    toggleActions: 'play none none reverse',
  })

  peopleRefs.current.forEach((person, index) => {
    const timeline = gsap.timeline()
    if (index % 4 === 0) {
      timeline.fromTo(
        person,
        {
          x: -50,
          y: 70,
          opacity: 0,
        },
        {
          x: 0,
          y: 0,
          opacity: 1,
        }
      )
    } else if (index % 4 === 1) {
      timeline.fromTo(
        person,
        {
          x: 0,
          y: 70,
          opacity: 0,
        },
        {
          x: 0,
          y: 0,
          opacity: 1,
        }
      )
    } else {
      timeline.fromTo(
        person,
        {
          x: 50,
          y: 70,
          opacity: 0,
        },
        {
          x: 0,
          y: 0,
          opacity: 1,
        }
      )
    }
    ScrollTrigger.create({
      trigger: person,
      start: 'top bottom',
      animation: timeline,
      toggleActions: 'play none none reverse',
    })
  })
}

const StyledSection = styled(Section)`
  padding-top: 0px;
  padding-bottom: 0px;
  background: -webkit-linear-gradient(#f2f2f2 40%, #ffffff 40%);

  ${match.isMD} {
    background: -webkit-linear-gradient(#f2f2f2 70%, #ffffff 70%);
  }
`

const StyledContainer = styled(Container)`
  position: relative;
  overflow: hidden;
`

const StyledHeading = styled(Heading)`
  font-weight: 900;
  z-index: 2;
`

const HeadingContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;

  ${match.isSM} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  ${match.isMD} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export default IdeasByThoughtLeader
