// i18next-extract-mark-ns-start index

import React from 'react'
import {
  Heading,
  match,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import { Trans } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import MediaTile from '../MediaTile/MediaTile'

const IdeaShareSection = ({ featuredContent, collections }) => {
  return (
    <ThemeProvider themeType="onBlack">
      <StyledSection name="Ideas we're excited to share">
        <Box bgcolor="background.default">
          <TextContainer maxWidth="md">
            <HeadingContainer>
              <Heading component="h2" variant="h2">
                <Trans>
                  Ideas we're
                  <br />
                  excited to share
                </Trans>
              </Heading>
            </HeadingContainer>
          </TextContainer>
        </Box>
        <TextContainer maxWidth="md">
          <Header component="h3" variant="h5">
            Take a deep dive into a single topic from multiple perspectives with
            our curated collections, articles, and videos.
          </Header>
          <SubHeading component="h4" variant="h6">
            Collections
          </SubHeading>
        </TextContainer>
        <CardContainer maxWidth="lg">
          <Grid container direction="row" spacing={3}>
            {!collections &&
              [...Array(4).keys()].map((_, index) => (
                <StyledGrid item xs={12} sm={6} md={3} key={index}>
                  <MediaTile isLoading={true} />
                </StyledGrid>
              ))}
            {collections &&
              collections.map((item) => (
                <StyledGrid item xs={12} sm={6} md={3} key={item.sys.id}>
                  <MediaTile {...item} poster={item.image.image} />
                </StyledGrid>
              ))}
          </Grid>
        </CardContainer>
        <TextContainer maxWidth="md">
          <HeadingContainer>
            <Heading component="h4" variant="h6">
              Articles and Videos
            </Heading>
          </HeadingContainer>
        </TextContainer>
        <CardContainer maxWidth="lg">
          <Grid container direction="row" spacing={3}>
            {!featuredContent &&
              [...Array(6).keys()].map((_, index) => (
                <StyledGrid item xs={12} sm={6} md={4} key={index}>
                  <MediaTile isLoading={true} />
                </StyledGrid>
              ))}
            {featuredContent &&
              featuredContent.map((item) => (
                <StyledGrid item xs={12} sm={6} md={4} key={item.sys.id}>
                  <MediaTile {...item} />
                </StyledGrid>
              ))}
          </Grid>
        </CardContainer>
      </StyledSection>
    </ThemeProvider>
  )
}

const StyledSection = styled(Section)`
  padding-top: 0px;
  ${match.isMD} {
    padding-bottom: 64px;
  }
`

const TextContainer = styled(Container)`
  text-align: center;
  padding: 32px 0 0;
`

const CardContainer = styled(Container)`
  position: relative;
  padding-top: 32px;
`

const StyledGrid = styled(Grid)`
  margin-bottom: 48px;
`

const HeadingContainer = styled.div`
  padding-top: 32px;

  ${match.isSM} {
    padding-top: 64px;
  }
`

const Header = styled(Heading)`
  padding-top: 24px;
`

const SubHeading = styled(Heading)`
  padding: 65px 0 0;
`

export default IdeaShareSection
