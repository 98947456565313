import React from 'react'
import {
  LazyLoad,
  useEnhancedContext,
  useInViewContext,
} from '@klickmarketing/react-components'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import styled from 'styled-components'
import * as THREE from 'three'

const tempObject = new THREE.Object3D()
const tempColor = new THREE.Color()
const LERP_COLOR = new THREE.Color()

const lerp = (v0, v1, t) => v0 * (1 - t) + v1 * t
const sqrt2 = Math.sqrt(2)
const quartCircleCurve = (x) => Math.sqrt(2 - Math.pow(x - sqrt2, 2))

const worldPointFromScreenPoint = (mouse, camera) => {
  const mouseVec = new THREE.Vector3(
    mouse.x,
    mouse.y,
    (camera.near + camera.far) / (camera.near - camera.far)
  ).unproject(camera)
  return mouseVec
}

let posRelativeToAvoid = {
  x: 0,
  y: 0,
}
let distance
let force
let output = {
  x: 0,
  y: 0,
  force: 0,
}
const getOffsetForceDirection = (position, avoid, maxDistance) => {
  posRelativeToAvoid.x = position.x - avoid.x
  posRelativeToAvoid.y = position.y + avoid.y

  distance = Math.sqrt(
    posRelativeToAvoid.x * posRelativeToAvoid.x +
      posRelativeToAvoid.y * posRelativeToAvoid.y
  )

  force = Math.max((maxDistance - distance) / maxDistance, 0)

  output.force = force
  output.x = ((posRelativeToAvoid.x / distance) * force) / 10
  output.y = ((posRelativeToAvoid.y / distance) * force) / 10

  return output
}

const ORIGIN = new THREE.Vector3(8, 1.5, 0)
const ORIGIN2 = new THREE.Vector3(-12, -5, 0)

let i = 0
let len = 0
const InstancedCircles = ({ data, varsRef, origin }) => {
  const colorArray = React.useMemo(
    () =>
      Float32Array.from(
        new Array(data.length)
          .fill()
          .flatMap((_, i) =>
            tempColor
              .set(
                LERP_COLOR.lerpColors(
                  varsRef.current.clearColor,
                  LERP_COLOR.set(data[i].color),
                  0.5 + i / data.length / 2
                )
              )
              .toArray()
          )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const meshRef = React.useRef()
  React.useEffect(() => {
    const renderInterval = setInterval(() => {
      if (!varsRef.current.isInView) return
      if (!meshRef.current) return
      for (i = 0, len = data.length; i < len; i++) {
        // calculate the target point
        varsRef.current.offsetForceFromMouse = getOffsetForceDirection(
          data[i],
          varsRef.current.mousePos,
          7
        )
        if (varsRef.current.offsetForceFromMouse.force > 0) {
          data[i].mouseOffset.x += varsRef.current.offsetForceFromMouse.x || 0
          data[i].mouseOffset.y += varsRef.current.offsetForceFromMouse.y || 0
        } else {
          data[i].mouseOffset.x = lerp(data[i].mouseOffset.x, 0, 0.1)
          data[i].mouseOffset.y = lerp(data[i].mouseOffset.y, 0, 0.1)
        }

        varsRef.current.progress = Math.sin(
          (varsRef.current.time * data[i].yRand) / 4
        )
        varsRef.current.xTarget = lerp(
          origin.x,
          origin.x + data[i].target.x * varsRef.current.progress,
          varsRef.current.progress
        )
        varsRef.current.yTarget = lerp(
          origin.y,
          origin.y + data[i].target.y * varsRef.current.progress,
          varsRef.current.progress
        )

        data[i].x =
          lerp(data[i].x, data[i].x + data[i].xVel, 0.1) + data[i].mouseOffset.x
        data[i].y =
          lerp(data[i].y, data[i].y + data[i].yVel, 0.1) + data[i].mouseOffset.y

        tempObject.position.set(data[i].x, data[i].y, data[i].z)

        varsRef.current.distance.x = varsRef.current.xTarget - data[i].x
        varsRef.current.distance.y = varsRef.current.yTarget - data[i].y

        data[i].xAcc = varsRef.current.distance.x + data[i].mouseOffset.x
        data[i].yAcc = varsRef.current.distance.y + data[i].mouseOffset.x

        varsRef.current.maxVel.x = quartCircleCurve(
          (Math.min(Math.abs(varsRef.current.distance.x), 10) / 10) * sqrt2
        )
        varsRef.current.maxVel.y = quartCircleCurve(
          (Math.min(Math.abs(varsRef.current.distance.y), 10) / 10) * sqrt2
        )

        data[i].xVel = Math.max(
          Math.min(data[i].xVel + data[i].xAcc, varsRef.current.maxVel.x),
          -varsRef.current.maxVel.x
        )
        data[i].yVel = Math.max(
          Math.min(data[i].yVel + data[i].yAcc, varsRef.current.maxVel.y),
          -varsRef.current.maxVel.y
        )

        tempObject.scale.setScalar(data[i].scale)
        tempObject.updateMatrix()
        meshRef.current?.setMatrixAt(i, tempObject.matrix)
      }

      meshRef.current.instanceMatrix.needsUpdate = true
    }, 1000 / 30)

    return () => {
      clearInterval(renderInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varsRef?.current?.isInView])

  useFrame(({ clock }) => {
    varsRef.current.time = clock.getElapsedTime()
  })

  return (
    <instancedMesh ref={meshRef} args={[null, null, data.length]}>
      <circleGeometry args={[0.6, 32]}>
        <instancedBufferAttribute
          attachObject={['attributes', 'color']}
          args={[colorArray, 3]}
        />
      </circleGeometry>
      <meshBasicMaterial
        vertexColors={THREE.VertexColors}
        opacity={0.5}
        transparent
      />
    </instancedMesh>
  )
}

const Scene = ({ varsRef, particleColors }) => {
  const particles = React.useMemo(
    () =>
      Array.from({ length: 40 }, (_, i) => {
        const angle = THREE.MathUtils.degToRad((360 / 40) * i)
        const targetX = Math.sin(angle) * (32 * (Math.random() - 0.5))
        const targetY = Math.cos(angle) * (45 * (Math.random() - 0.5))
        const x = 40 * Math.random() - 20
        const y = 40 * Math.random() - 20
        return {
          color: particleColors[i % particleColors.length],
          scale: 1 + Math.random() * 10 * Math.random(),
          xRand: Math.random(),
          yRand: Math.random(),
          mouseOffset: { x: 0, y: 0 },
          xVel: 0,
          yVel: 0,
          xAcc: 0,
          yAcc: 0,
          x,
          y,
          z: i / 40,
          target: { x: targetX, y: targetY },
        }
      }),
    [particleColors]
  )
  const particles2 = React.useMemo(
    () =>
      Array.from({ length: 30 }, (_, i) => {
        const angle = THREE.MathUtils.degToRad((360 / 30) * i)
        const targetX = Math.sin(angle) * (50 * (Math.random() - 0.5))
        const targetY = Math.cos(angle) * (45 * (Math.random() - 0.5))
        const x = 40 * Math.random() - 20
        const y = 40 * Math.random() - 20
        return {
          color: particleColors[i % particleColors.length],
          scale: 1 + Math.random() * 2.5 * Math.random(),
          xRand: Math.random(),
          yRand: Math.random(),
          mouseOffset: { x: 0, y: 0 },
          xVel: 0,
          yVel: 0,
          xAcc: 0,
          yAcc: 0,
          x,
          y,
          z: i / 30,
          target: { x: targetX, y: targetY },
        }
      }),
    [particleColors]
  )

  return (
    <>
      <InstancedCircles
        data={particles}
        radius={10}
        varsRef={varsRef}
        origin={ORIGIN}
      />
      <InstancedCircles
        data={particles2}
        radius={10}
        varsRef={varsRef}
        origin={ORIGIN2}
      />
    </>
  )
}

const default2 = [0xffffff]
const default1 = 0x01299a

const ParticleBackdrop = ({
  parentRef,
  clearColor = default1,
  particleColors = default2,
  disableMouseTracking,
  showBackdrop,
}) => {
  const { isInView } = useInViewContext()
  const { enhanced } = useEnhancedContext()
  const containerRef = React.useRef()
  const varsRef = React.useRef({
    progress: 0,
    radius: 1,
    angle: 1,
    xTarget: 0,
    yTarget: 0,
    distanceFromOrigin: 1,
    normalizedDist: 1,
    lerpedColor: 0,
    scale: 0,
    time: 0,
    clearColor: new THREE.Color(clearColor),
    isInView: true,
    offsetForceFromMouse: {
      force: 0,
      x: 0,
      y: 0,
    },
    distance: {
      x: 0,
      y: 0,
    },
    maxVel: {
      x: 0,
      y: 0,
    },
    sceneCameraBounds: {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    },
    mousePos: { x: 0, y: 0 },
  })

  React.useEffect(() => {
    if (!window || !!disableMouseTracking || !isInView || !enhanced) return
    const width = window.innerWidth
    const height = window.innerHeight
    const handleMouseMove = (e) => {
      varsRef.current.mousePos.x =
        varsRef.current.sceneCameraBounds.left +
        varsRef.current.sceneCameraBounds.width * ((e.clientX / width) * 1.1)
      varsRef.current.mousePos.y =
        varsRef.current.sceneCameraBounds.top +
        varsRef.current.sceneCameraBounds.height * ((e.clientY / height) * 1.1)
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [parentRef, disableMouseTracking, isInView, enhanced])

  React.useEffect(() => {
    varsRef.current.isInView = isInView
  }, [isInView])

  const threeJSCanvas = React.useMemo(() => {
    if (!showBackdrop || !enhanced) {
      return null
    }
    return (
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        linear
        flat
        orthographic
        pixelRatio={0.1}
        gl={{ antialias: false, alpha: false }}
        camera={{ zoom: 45, position: [0, 0, 15] }}
        onCreated={({ gl, camera }) => {
          if (!containerRef.current) return

          gl.setClearColor('#' + new THREE.Color(clearColor).getHexString(), 1)
          containerRef.current.style.opacity = 1
          const topLeft = worldPointFromScreenPoint(
            { x: -0.5, y: -0.5 },
            camera
          )
          const bottomRight = worldPointFromScreenPoint(
            { x: 0.5, y: 0.5 },
            camera
          )
          varsRef.current.sceneCameraBounds.top = topLeft.y
          varsRef.current.sceneCameraBounds.left = topLeft.x
          varsRef.current.sceneCameraBounds.width = bottomRight.x - topLeft.x
          varsRef.current.sceneCameraBounds.height = bottomRight.y - topLeft.y
        }}
      >
        <Scene varsRef={varsRef} particleColors={particleColors} />
      </Canvas>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBackdrop, enhanced])

  return (
    <CanvasContainer ref={containerRef}>
      <LazyLoad style={{ width: '100%', height: '100%' }}>
        {threeJSCanvas}
      </LazyLoad>
    </CanvasContainer>
  )
}

const CanvasContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  filter: blur(12px);
  opacity: 0;
  transition: opacity 0.5s;

  * {
    pointer-events: all !important;
  }
`

export default ParticleBackdrop
