import React from 'react'
import { Button, ThemeProvider } from '@klickmarketing/react-components'
import { Link as I18nLink } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

const TagList = ({
  tags,
  themeType = 'onPrimary',
  gap = '8px',
  size,
  ...rest
}) => {
  return (
    <ThemeProvider themeType={themeType}>
      <Tags gap={gap} {...rest}>
        {tags.map((tag) => (
          <TagItem key={tag.sys.id}>
            <Tag {...tag} size={size} />
          </TagItem>
        ))}
      </Tags>
    </ThemeProvider>
  )
}

const Tag = ({ name, size }) => {
  return (
    <Button
      component={I18nLink}
      to={`/discover?search=${encodeURIComponent(name)}`}
      size={size}
    >
      {name}
    </Button>
  )
}

const Tags = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap};
`

const TagItem = styled.li``

export default TagList
