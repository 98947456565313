import React from 'react'
import {
  Button,
  Heading,
  match,
  P,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import {
  Box,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  Link as I18nLink,
  Trans,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

const AboutSection = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()
  const { macMock1 } = useStaticQuery(graphql`
    {
      macMock1: file(relativePath: { eq: "About/MacMock1.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
            breakpoints: [630, 900, 1350, 1920]
            sizes: "(min-width: 1280px) 1230px, 100vw"
          )
        }
      }
    }
  `)
  return (
    <ThemeProvider themeType="onWhite">
      <StyledSection name={t('About IDX', { ns: 'index' })}>
        <Container maxWidth="lg">
          <ContentWrapper to="/about">
            <Grid container spacing={isDesktop ? 8 : 0}>
              <Grid item xs={12} md={6}>
                <Box my={4}>
                  <StyledImage image={getImage(macMock1)} alt="IDX on a Mac" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Content>
                  <Box my={4}>
                    <Heading component="h3" variant="h4">
                      <Trans ns="index">About IDX</Trans>
                    </Heading>
                  </Box>
                  <Box my={0}>
                    <P variant="blurb2">
                      <Trans ns="index">
                        Great ideas shouldn’t be kept to themselves, not if we
                        want to build a better future. When we collaborate, we
                        create opportunities to unlock true innovation and spark
                        the ideas that will change our world—for good.
                      </Trans>
                    </P>
                  </Box>
                  <Box my={4}>
                    <Button size="large" tabIndex="-1">
                      <Trans ns="index">Learn More About IDX</Trans>
                    </Button>
                  </Box>
                </Content>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Container>
      </StyledSection>
    </ThemeProvider>
  )
}

const StyledSection = styled(Section)`
  background: #f2f2f2;
  overflow: hidden;
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  margin-left: -5%;

  ${match.isMD} {
    margin-left: 0px;
  }
`

const ContentWrapper = styled(I18nLink)`
  display: block;
  text-decoration: none;

  &:active,
  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${match.isMD} {
    align-items: flex-start;
    text-align: left;
  }
`

export default AboutSection
