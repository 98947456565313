import React from 'react'
import { P } from '@klickmarketing/react-components'
import { Link as I18nLink } from 'gatsby-plugin-react-i18next'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'

const ThoughtLeader = ({
  elementRef,
  src,
  firstName,
  lastName,
  title,
  url,
}) => {
  return (
    <CardContainer
      ref={elementRef}
      aria-label={`Go to Idea by ${firstName} ${lastName}`}
      to={url}
    >
      <ImageContainer>
        <CardBackdropOverlay />
        <CardImages>
          <CardHead role="presentation" alt="" src={src} objectFit="contain" />
          <CardImageLabel>
            <ArrowAnim>
              <ArrowHead />
              <ArrowBody />
            </ArrowAnim>
            <StyledHeading component="h4" variant="h6">
              {firstName}
              <br />
              {lastName}
            </StyledHeading>
          </CardImageLabel>
        </CardImages>
      </ImageContainer>
      <CardLabel>
        <StyledHeading component="h4" variant="subtitle1">
          {firstName}
          <br />
          {lastName}
        </StyledHeading>
        <StyledP>
          <Markdown>{title}</Markdown>
        </StyledP>
      </CardLabel>
    </CardContainer>
  )
}

const StyledHeading = styled(P)`
  font-family: 'PublicaSans';
  font-style: normal;
  font-weight: 900;
  line-height: 0.9em;
  margin-bottom: 0px;
  margin-top: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-decoration: none;
`

const StyledP = styled(P)`
  font-family: 'PublicaSans';
  margin: 0em;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25em;
  letter-spacing: -0.02em;
`

const ArrowAnim = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  margin-right: 8px;
`

const ArrowHead = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 0px;
  width: 0px;
  transition: all 0.3s;
  transition-delay: 0.2s;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 2px;
    background: white;
    border-radius: 8px;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0px;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 8px;
  }
`

const ArrowBody = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 0px;
  width: 0px;
  transition: all 0.3s;
  transition-delay: 0.2s;

  &::after {
    content: '';
    position: absolute;
    top: 0.25px;
    right: 1px;
    height: 2px;
    width: 141%;
    background: white;
    border-radius: 8px;
    transform: rotate(-45deg);
    transform-origin: top right;
  }
`

const CardImageLabel = styled.div`
  position: absolute;
  bottom: -75px;
  padding-left: 24px;
  padding-top: 64px;
  width: 100%;
  color: white;
  font-size: 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  transition: all 0.3s;
  display: flex;
  align-items: flex-start;
  opacity: 0;

  ${StyledHeading} {
    margin-top: -6px;
    color: ${({ theme }) => theme.palette.common.white};
  }
`

const CardLabel = styled.div`
  transition: all 0.3s;
  width: 100%;
`

const CardHead = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  object-fit: cover;
  transition: all 0.3s;
  display: block;

  & > * {
    max-width: unset !important;
  }
`

const CardBackdropOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  overflow: hidden;
  background: #9f9f9f;
  transition: all 0.3s;
  box-shadow: none;
`

const CardImages = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  bottom: 0px;
  overflow: visible;
  clip-path: polygon(0% -100%, 100% -100%, 100% 100%, 0% 100%);
  transition: all 0.3s;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const CardContainer = styled(I18nLink)`
  opacity: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  overflow-x: visible;
  cursor: pointer;
  text-decoration: none;
  margin: auto;
  margin-bottom: 10%;

  & > * {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    ${CardImages} {
      bottom: -25px;
      height: 200%;
    }

    ${CardBackdropOverlay} {
      background: rgb(3, 67, 251);
      bottom: -25px;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }

    ${CardHead} {
      transform: scale(1.25);
      bottom: 25px;
    }

    ${CardImageLabel} {
      bottom: 0px;
      opacity: 1;
      padding-bottom: 16px;
    }

    ${CardLabel} {
      transform: translateY(-50px);
      opacity: 0;
    }

    ${ArrowHead} {
      height: 12px;
      width: 12px;
    }

    ${ArrowBody} {
      height: 16px;
      width: 16px;
    }
  }
`

export default ThoughtLeader
