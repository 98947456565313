import React from 'react'
import { gql } from '@apollo/client'
import { Heading, Section } from '@klickmarketing/react-components'
import { Container } from '@material-ui/core'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { orderBy as _orderBy } from 'lodash'
import styled from 'styled-components'

import { DEFAULT_LOCALE } from '../../../languages'
import { useQueryLatestData } from '../../hooks/useQueryLatestData'
import TagsList from '../TagList/TagList'

const TagsSection = () => {
  const { language, t } = useI18next()
  const { latestData } = useQueryLatestData(GET_POPULAR_TAG_DATA, {
    variables: { locale: language || DEFAULT_LOCALE },
  })
  const tags = _orderBy(
    latestData?.tagCollection?.items,
    ['linkedFrom.entryCollection.total'],
    ['desc']
  )

  return (
    <Section name={t('Popular Tags')}>
      <TextContainer maxWidth="md">
        <HeadingContainer>
          <Heading component="h2" variant="h4">
            <Trans>Popular Topics</Trans>
          </Heading>
        </HeadingContainer>
      </TextContainer>
      <Container maxWidth="lg">
        <StyledTagList
          tags={tags}
          themeType="onWhite"
          gap="12px"
          size="large"
        />
      </Container>
    </Section>
  )
}

const TextContainer = styled(Container)`
  text-align: center;
`

const HeadingContainer = styled.div`
  padding-bottom: 32px;
`

const StyledTagList = styled(TagsList)`
  justify-content: center;
`

const GET_POPULAR_TAG_DATA = gql`
  query ($locale: String!) {
    tagCollection(limit: 21, order: sys_publishedAt_DESC, locale: $locale) {
      items {
        name
        sys {
          id
        }
        linkedFrom {
          entryCollection {
            total
          }
        }
      }
    }
  }
`

export default TagsSection
