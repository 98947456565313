import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { tracking } from '@klickmarketing/react-components'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'

import { IDX_FLOODLIGHT_TAGS } from '../../config'
import { DEFAULT_LOCALE } from '../../languages'
import AboutSection from '../components/AboutSection/AboutSection'
import IdeasByEvent from '../components/IdeasByEvent/IdeasByEvent'
import IdeasByThoughtLeader from '../components/IdeasByThoughtLeader/IdeasByThoughtLeader'
import IdeaShareSection from '../components/IdeaShare/IdeaShareSectionV2'
import IntroSectionV3 from '../components/IntroSection/IntroSectionV3'
import Layout from '../components/Layout/Layout'
import TagsSection from '../components/TagsSection/TagsSection'

const IndexPage = ({ location }) => {
  const [loadedData, setLoadedData] = React.useState()
  const { language, t } = useI18next()
  const { data } = useQuery(GET_HOMEPAGE_DATA, {
    variables: {
      locale: language || DEFAULT_LOCALE,
    },
  })

  React.useEffect(() => {
    if (!data) return

    setLoadedData(data)
  }, [data])

  React.useEffect(() => {
    tracking.track({ dcCode: IDX_FLOODLIGHT_TAGS.PAGE_LOAD_HOMEPAGE })
  }, [])

  const homeData = loadedData && loadedData.homeCollection.items[0]
  const collectionData =
    loadedData && loadedData.collectionsPageCollection.items[0]

  return (
    <Layout
      seoProps={{
        title: t(`Bringing the Future of Health to Life`, { ns: 'index' }),
        description: t(
          'The Klick Ideas Exchange is a space to share ideas that excite us. Explore our collection of videos and articles featuring innovative thought leaders.',
          { ns: 'index' }
        ),
      }}
      location={location}
    >
      <IntroSectionV3 />
      <IdeaShareSection
        featuredContent={loadedData && homeData.featuredContentCollection.items}
        collections={loadedData && collectionData.collectionsCollection.items}
      />
      <IdeasByThoughtLeader
        items={loadedData && homeData.ideasByThoughtLeaderCollection.items}
      />
      <IdeasByEvent
        items={loadedData && homeData.ideasByEventCollection.items}
      />
      <TagsSection />
      <AboutSection />
    </Layout>
  )
}

const GET_HOMEPAGE_DATA = gql`
  query GetHomePageData($locale: String!, $preview: Boolean) {
    homeCollection(
      preview: $preview
      where: { slug: "home" }
      limit: 1
      locale: $locale
    ) {
      items {
        featuredContentCollection(limit: 10) {
          items {
            ... on VideoPost {
              sys {
                id
              }
              __typename
              slug
              title
              description
              videoAsset {
                duration
                thumbnail {
                  url
                  height
                  width
                }
              }
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                }
              }
            }
            ... on ArticlePost {
              sys {
                id
              }
              __typename
              title
              slug
              description
              contentfulMetadata {
                tags {
                  id
                  name
                }
              }
              heroImage {
                image {
                  url
                  height
                  width
                }
              }
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                }
              }
            }
          }
        }
        featuredPoVsCollection(limit: 10) {
          items {
            ... on ArticlePost {
              sys {
                id
              }
              __typename
              title
              slug
              description
              contentfulMetadata {
                tags {
                  id
                  name
                }
              }
              heroImage {
                image {
                  url
                  height
                  width
                }
                focalPoint
              }
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                }
              }
              linkedFrom {
                collectionCollection(limit: 1) {
                  items {
                    sys {
                      id
                    }
                    title
                    slug
                  }
                }
              }
            }
          }
        }
        ideasByEventCollection(limit: 20) {
          items {
            title
            image {
              url
              height
              width
            }
          }
        }
        ideasByThoughtLeaderCollection(limit: 20) {
          items {
            firstName
            lastName
            title
            url
            image {
              url
              height
              width
            }
          }
        }
      }
    }
    collectionsPageCollection(where: { slug: "collections" }, limit: 1) {
      items {
        collectionsCollection(limit: 4) {
          items {
            sys {
              id
            }
            slug
            title
            description
            image {
              focalPoint
              image {
                url
                height
                width
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
