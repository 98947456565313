import React from 'react'
import { Heading } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { Link as I18nLink } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

const EventCard = ({ to, title, src }) => {
  return (
    <Card component={I18nLink} to={to}>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
      <Content>
        <StyledHeading variant="h5" component="h4">
          {title}
        </StyledHeading>
      </Content>
      <ImageContainer>
        <Image src={src} alt={`Image of ${title}`} />
      </ImageContainer>
    </Card>
  )
}

const StyledHeading = styled(Heading)`
  line-height: 1em;
`

const ArrowContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: #000;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  /* transform: scale(0); */
  overflow: hidden;
  transition: transform 250ms ease-out, opacity 100ms ease-out;
`
const Arrow = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-width: 4px 4px 0 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
  margin: 10px;
  transform: translate3d(1px, -1px, 0) scale(0.3);
  transition: all 250ms ease-out;
  transition-delay: 175ms;

  &:before {
    content: '';
    box-sizing: border-box;
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    // opacity: 0;
    box-shadow: inset 0 0 0 32px;
    transform: rotate(-45deg) translate3d(-90px, 0, 0);
    width: 35px;
    transform-origin: right top;
    transition: transform 250ms ease-out, opacity 200ms ease-out;
    transition-delay: 175ms;
  }
`

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 10% 10% 10%;
  z-index: 3;
  width: 100%;
  text-shadow: 2px 2px 9px #000;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: #3749e5;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    top: 85%;
    left: 40px;
    z-index: 2;
    transform: translate3d(-50%, -50%, 0) scale(0);
    transition: transform 250ms ease-out;
    mix-blend-mode: multiply;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      opacity: 0.7;
      background: #0919a9;
      mix-blend-mode: normal;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: grayscale(1);
  display: block;
  transition: 0.5s !important;
`

const Card = styled(Box)`
  position: relative;
  background: black;
  cursor: pointer;
  color: #fff;
  overflow: hidden;
  display: block;

  text-decoration: none;
  filter: drop-shadow(30px 30px 50px rgba(0, 0, 0, 0.15));

  &:hover,
  &:focus {
    ${ImageContainer}:before {
      transform: translate3d(-50%, -50%, 0) scale(5.3);
    }

    .image {
      opacity: 1;
    }

    ${ArrowContainer} {
      opacity: 1;
    }

    ${Arrow} {
      border-color: rgba(255, 255, 255, 1);
      &:before {
        opacity: 1;
        transform: rotate(-45deg) translate3d(0, 0, 0);
      }
    }
  }
`

export default EventCard
