import React from 'react'
import {
  Heading,
  match,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import EventCard from './EventCard'

const XS_ROW = 6
const SM_ROW = 3

const IdeasByEvent = ({ items }) => {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <StyledSection name={t('Ideas by event', { ns: 'index' })}>
      <Container maxWidth="lg">
        <ThemeProvider themeType="onWhite">
          <TextContainer maxWidth="md">
            <HeadingContainer>
              <Heading component="h2" variant="h2">
                <Trans>Ideas by event</Trans>
              </Heading>
            </HeadingContainer>
          </TextContainer>
        </ThemeProvider>
        <ThemeProvider themeType="onBlack">
          <Grid container spacing={isMedium ? 1 : 3} justifyContent="center">
            {items ? (
              <IdeasByEventGrid items={items} />
            ) : (
              <IdeasByEventSkeleton />
            )}
          </Grid>
        </ThemeProvider>
      </Container>
    </StyledSection>
  )
}

const IdeasByEventGrid = ({ items }) => {
  return items
    .filter((item) => item.title && item.image)
    .map((item, index) => (
      <Grid item xs={XS_ROW} sm={SM_ROW} key={`ideasByEvent@key=${index}`}>
        <EventCard
          to={`/discover?event=${item.title.replaceAll(' ', '+')}`}
          title={item.title}
          src={item.image.url}
        />
      </Grid>
    ))
}

const IdeasByEventSkeleton = () => {
  return [...Array(8)].map((item, index) => (
    <Grid item xs={XS_ROW} sm={SM_ROW} key={`ideasByEvent@key=${index}`}>
      <Skeleton variant="rect" style={{ height: '300px', width: '100%' }} />
    </Grid>
  ))
}

const StyledSection = styled(Section)`
  padding-top: 0px;
`

const HeadingContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  ${match.isSM} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  ${match.isMD} {
    /* padding-top: 96px; */
    padding-bottom: 96px;
  }
`

const TextContainer = styled(Container)`
  text-align: center;
`

export default IdeasByEvent
